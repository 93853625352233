import Splide from '@splidejs/splide';

///
// Gallery slider
///

const gallerySplides = document.querySelectorAll('.splide-gallery')
if (gallerySplides.length) {
  for (let i = 0; i < gallerySplides.length; i++) {

    var splideElement
    splideElement = gallerySplides[i]


    const SliderModalID = splideElement.getAttribute('data-modalid');
    const SliderModalElement = document.querySelectorAll('.splide-modal[data-modalid='+SliderModalID+']');

    const splideDefaultOptions =
      {
        type: 'loop',
        autoplay: false,
        interval: 7000,
        pauseOnHover: false,
        arrows: true,
        perPage: 2,
        perMove: 1,
        gap: '3rem',
        pagination: false,
        isNavigation: true,
        keyboard: true, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
        slideFocus: true, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
        mediaQuery: 'min',
        breakpoints: {
          1: {
            perPage: 1
          },
          576: {
            perPage: 2
          },
          768: {
            perPage: 2
          },
          992: {
            perPage: 3
          },
          1200: {
            perPage: 3
          },
          1400: {
            perPage: 3
          }
        }
      }
    const splide = new Splide(splideElement, splideDefaultOptions)

    // Modal

    const splideDefaultOptionsModal =
      {
        type: 'loop',
        arrows: true,
        perPage: 1,
        perMove: 1,
        autoWidth: true,
        pagination: false
      }
    const splideModal = new Splide(SliderModalElement[0], splideDefaultOptionsModal)

    splide.sync( splideModal );
    splide.mount();
    splideModal.mount();

    let myModalEl = document.getElementsByClassName("modal--gallery");
    for (let i = 0; i < myModalEl.length; i++) {
      myModalEl[i].addEventListener('shown.bs.modal', function (event) {
        splideModal.refresh();
      });
    }

  }
}
