// Check if prefer-reduced-motion is set (a11y)
const preferReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

// Update static countdown
function updateStaticCountdown(days, hours, minutes, staticCountdownElement) {
  const staticValues = staticCountdownElement.querySelectorAll('.static-value');
  staticValues[0].textContent = days;
  staticValues[1].textContent = hours;
  staticValues[2].textContent = minutes;
}

// Update animated countdown
function updateCountdown(timestamp, animatedCountdownElement, staticCountdownElement) {
  const now = new Date().getTime();
  const distance = timestamp - now;

  if (distance < 0) {
    // eslint-disable-next-line no-param-reassign
    animatedCountdownElement.innerHTML = 'EXPIRED';
    return;
  }

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  const counterValues = animatedCountdownElement.querySelectorAll('.counter-value');
  counterValues[0].textContent = days;
  counterValues[1].textContent = hours;
  counterValues[2].textContent = minutes;
  counterValues[3].textContent = seconds;

  updateStaticCountdown(days, hours, minutes, staticCountdownElement);
}

document.addEventListener('DOMContentLoaded', () => {
  const countdownElement = document.querySelector('.countdown');

  if (countdownElement) {
    const animatedCountdownElement = countdownElement.querySelector('.countdown__counter');
    const staticCountdownElement = countdownElement.querySelector('.countdown__static');

    const timestamp = parseInt(countdownElement.dataset.timestamp, 10) * 1000;

    if (!preferReducedMotion) {
      setInterval(() => {
        updateCountdown(timestamp, animatedCountdownElement, staticCountdownElement);
      }, 1000);
    }

    const initialDays = Math.floor(timestamp / (1000 * 60 * 60 * 24));
    const initialHours = Math.floor((timestamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const initialMinutes = Math.floor((timestamp % (1000 * 60 * 60)) / (1000 * 60));
    updateStaticCountdown(initialDays, initialHours, initialMinutes, staticCountdownElement);
  }
});
